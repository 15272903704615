import "slick-carousel";
import "slick-lightbox";
import navbar from "./modules/navbar";
import loader from "./modules/loader";
import landingPage from "./modules/landingPage";
import register from "./modules/register";
import Rellax from "./modules/rellax.min";
import training from "./modules/training";
import checkout from "./modules/checkout";
import axios from "axios";

if (document.getElementById("cartComplete")) {
  checkout();
}

if (document.getElementsByTagName("body")[0]) {
  loader.init();
}

if (document.getElementById("landingpage")) {
  landingPage.init();
}

if (document.getElementById("landingPub")) {
  landingPage.init();
}

if (document.getElementById("training")) {
  training.init();
}

if (typeof userLogin !== "undefined") {
  // Logged in only
  navbar.init();
} else if (document.getElementById("registerPage")) {
  register.init();
}

// Carousel
$(document).ready(function() {
  // Admin Script - enable form to change Print Proof PDF
  if (document.getElementById("productindex")) {
    const page = document.querySelector(".creative");

    function printModalHandler(e) {
      e.preventDefault();
      page.innerHTML += `
				<div class='printproofwindow' id="printproofwindow">
					<div id='printproofwindow' class='modal marketingsub' style="opacity: 1; visibility: visible;">
						<div class="inner">
							<div class="modal-content">
								<h1 style="margin-bottom: 5px;">Check Proof Link<span class="white">.</span></h1>
								<p style="color: #fff; margin-bottom: 15px;">Product: ${e.target.dataset.urn}</p>
                <div class='sublinks' id='sublinks'>
									<a style="color: #ffa74d;" href="${e.target.dataset.proof}" target="_blank">View Current Proof File</a>
								</div>
								<form action='/products/printproof/${e.target.dataset.id}' method='POST' enctype="multipart/form-data" class="form" id='printproofreplace'>
									<input type='hidden' name='hub' value='affinitystore'>
									<input type='hidden' name="section" value='${e.target.dataset.cat}'>
									<input type='hidden' name="category" value='${e.target.dataset.urn}'>
									<input type='hidden' name="name" value='replacedproof'>
									<div class="form__group">
										<input class="form__input" type='file' accept="application/pdf" name='printproofupload'/>
									</div>
									<div class="form__group">
										<input class="form__input btn btn--trans_yel" type='submit'/>
									</div>
								</form>
								<button class='ppclose'>Close</button>
							</div>
						</div>
					</div>
				</div>
			`;

      $(".ppclose").on("click", () => {
        document.location.reload();
      });
    }

    $(".printproofchange").on("click", (e) => {
      printModalHandler(e);
    });
  }

  // Replace SLICK carousel with something else due to this issue
  // Whihc is why below look nasty
  // https://github.com/kenwheeler/slick/issues/1071

  // Sub category scroll
  const secondaryNavList = [].slice.call(document.querySelectorAll(".cat-header__list .cat-header__item"));
  const catTarget = document.querySelector(".cat-header__list .current");
  const needle = secondaryNavList.indexOf(catTarget);
  let catSettings;

  catSettings = {
    infinite: true,
    centerMode: true,
    centerPadding: "0",
    slidesToShow: 8,
    swipeToSlide: true,
    variableWidth: true,
    initialSlide: needle,
    rows: 0,
    prevArrow: '<img class="slick-prev" src="/assets/reach/img/arr-l.svg"></img>',
    nextArrow: '<img class="slick-next" src="/assets/reach/img/arr-r.svg"></img>',
  };

  // Init slick carousel
  $(".cat-header__list").slick(catSettings);

  // Hacks ish
  if (window.location.pathname === "/products/financial") {
    // Sub category
    $("#sub-cat").slick({
      centerMode: true,
      centerPadding: "0px",
      slidesToShow: 2,
      responsive: [
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
      prevArrow: '<img class="slick-prev" src="/assets/reach/img/arr-l.svg"></img>',
      nextArrow: '<img class="slick-next" src="/assets/reach/img/arr-r.svg"></img>',
    });
  }

  if (window.location.pathname === "/products/seasonal") {
    // Sub category
    $("#sub-cat").slick({
      centerMode: true,
      centerPadding: "0px",
      slidesToShow: 5,
      responsive: [
        {
          breakpoint: 1020,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
      prevArrow: '<img class="slick-prev" src="/assets/reach/img/arr-l.svg"></img>',
      nextArrow: '<img class="slick-next" src="/assets/reach/img/arr-r.svg"></img>',
    });
  }

  if (window.location.pathname === "/products/retail") {
    // Sub category
    $("#sub-cat").slick({
      centerMode: true,
      centerPadding: "0px",
      slidesToShow: 3,
      responsive: [
        {
          breakpoint: 1150,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 1030,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
      prevArrow: '<img class="slick-prev" src="/assets/reach/img/arr-l.svg"></img>',
      nextArrow: '<img class="slick-next" src="/assets/reach/img/arr-r.svg"></img>',
    });
  }

  if (window.location.pathname === "/products/automotive") {
    // Sub category
    $("#sub-cat").slick({
      centerMode: true,
      centerPadding: "0px",
      slidesToShow: 3,
      responsive: [
        {
          breakpoint: 880,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 620,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
      prevArrow: '<img class="slick-prev" src="/assets/reach/img/arr-l.svg"></img>',
      nextArrow: '<img class="slick-next" src="/assets/reach/img/arr-r.svg"></img>',
    });
  }

  // Close flash message
  $(".flash-close").on("click", function() {
    this.parentNode.parentNode.remove();
  });

  // Fix navigation only for logged in users
  if (typeof userLogin !== "undefined") {
    $(window).scroll(function() {
      const sc = $(window).scrollTop();

      if (sc > 40) {
        $("body").addClass("fixed");
      } else {
        $("body").removeClass("fixed");
      }

      if (!document.getElementById("landingpage")) {
        if (sc > 271) {
          $(".cat-header").addClass("fixed");
        } else {
          $(".cat-header").removeClass("fixed");
        }
      }
    });
  }

  // Single page
  if (typeof showmodal !== "undefined") {
    grabProduct(event);

    setTimeout(function() {
      const modal = document.querySelector(".modal");
      // var backButton = document.querySelector(".back-button");
      const closeButton = document.querySelector(".close-button");

      function toggleModal() {
        modal.classList.toggle("show-modal");
      }

      function modalDestroy() {
        toggleModal();
        setTimeout(function() {
          modal.remove();
        }, 300);
      }

      function windowOnClick(event) {
        console.log("modal");
        if (event.target === modal) {
          modalDestroy();
        }
      }

      // Show modal after content is loaded
      toggleModal();
      // backButton.addEventListener("click", modalDestroy);

      // Share  link
      shareLink();
    }, 200);
  }

  // Category page
  const productButton = document.querySelectorAll(".products__link");
  productButton.forEach(function(event) {
    $(event).on("click", function(event) {
      event.preventDefault();
      grabProduct(event);

      setTimeout(function() {
        const modal = document.querySelector(".modal");
        // var backButton = document.querySelector(".back-button");
        const closeButton = document.querySelector(".close-button");

        function toggleModal() {
          modal.classList.toggle("show-modal");
        }

        function modalDestroy() {
          toggleModal();
          setTimeout(function() {
            modal.remove();
          }, 300);
        }

        function windowOnClick(event) {
          if (event.target === modal) {
            modalDestroy();
          }
        }

        // Show modal after content is loaded
        toggleModal();
        closeButton.addEventListener("click", modalDestroy);
        // backButton.addEventListener("click", modalDestroy);
        window.addEventListener("click", windowOnClick);

        // Share  link
        shareLink();
      }, 200);
    });
  });

  // Load products for modal
  function grabProduct(event) {
    let htmlBody = document.querySelector(".modal");
    let showForm;
    let sharebBackButton;
    let region; // Stores region
    const categoryProduct = []; // Stores all products sorted By URN
    let htmlsource; // Stores the modal container
    let productIndex; // Retrieve a specific product from provided array, ound in index.pug
    let productLink; // Store he individual product
    let skipMain = false;

    let displaySize = {
      largeMobileBanner: false,
      leaderboard: false,
      mma: false,
      mpu: false,
      skin: false,
      skyscraper: false,
      printHalfpage: false,
    };
    let mockupMetaOrder = [];

    if (typeof showmodal === "undefined") {
      productLink = event.currentTarget.href.split("/").pop();
    } else {
      productLink = window.location.href.split("/").pop();
    }

    storeProducts.forEach(function(item) {
      categoryProduct[item.product.urn] = item;
    });

    productIndex = categoryProduct[productLink].product;
    productIndex.filters = categoryProduct[productLink].filters;
    region = categoryProduct[productLink]._id;
    htmlBody = document.querySelector("body");

    if (typeof userLogin !== "undefined") {
      for (let i = 0; i < productIndex.mockupMeta.length; i++) {
        if (productIndex.mockupMeta[i].name.endsWith("_LARGE MOBILE BANNER.jpg")) displaySize.largeMobileBanner = true;
        if (productIndex.mockupMeta[i].name.endsWith("_LEADERBOARD.jpg")) displaySize.leaderboard = true;
        if (productIndex.mockupMeta[i].name.endsWith("_MMA.jpg")) displaySize.mma = true;
        if (productIndex.mockupMeta[i].name.endsWith("_MPU.jpg")) displaySize.mpu = true;
        if (productIndex.mockupMeta[i].name.endsWith("_SKIN.jpg")) displaySize.skin = true;
        if (productIndex.mockupMeta[i].name.endsWith("_SKYSCRAPER.jpg")) displaySize.skyscraper = true;
        // console.log(productIndex.mockupMeta[i].name)

        if (
          // productIndex.mockupMeta[i].name.toLowerCase().endsWith("print halfpage.jpg") ||
          // productIndex.mockupMeta[i].name.toLowerCase().endsWith("_print half page.jpg")
          productIndex.mockupMeta[i].name.endsWith("_print halfpage.jpg") ||
          productIndex.mockupMeta[i].name.endsWith("_PRINT HALFPAGE.jpg") ||
          productIndex.mockupMeta[i].name.endsWith("_Print Halfpage.jpg") ||
          productIndex.mockupMeta[i].name.endsWith("_Print halfpage.jpg") ||
          productIndex.mockupMeta[i].name.endsWith("_Print half page.jpg") ||
          productIndex.mockupMeta[i].name.endsWith("_print half page.jpg")
        )
          displaySize.printHalfpage = true;
console.log(productIndex.mockupMeta[i])
        mockupMetaOrder.push(
          productIndex.mockupMeta[i].name.endsWith('-full') ? productIndex.mockupMeta[i].name.substring(productIndex.mockupMeta[i].name.indexOf("_"), productIndex.mockupMeta[i].name.length-5) : productIndex.mockupMeta[i].name.substring(productIndex.mockupMeta[i].name.indexOf("_"), productIndex.mockupMeta[i].name.length)
        );
      }
      console.log(mockupMetaOrder);
      const largeMobileBannerIndex = mockupMetaOrder.indexOf("_LARGE MOBILE BANNER.jpg") + 1;
      const leaderboardIndex = mockupMetaOrder.indexOf("_LEADERBOARD.jpg") + 1;
      const mmaIndex = mockupMetaOrder.indexOf("_MMA.jpg") + 1;
      const mpuIndex = mockupMetaOrder.indexOf("_MPU.jpg") + 1;
      const skinIndex = mockupMetaOrder.indexOf("_SKIN.jpg") + 1;
      const skyscraperIndex = mockupMetaOrder.indexOf("_SKYSCRAPER.jpg") + 1;
      const printHalfpageIndex = mockupMetaOrder.indexOf("_print halfpage.jpg") + 1;
      const printHalfpageIndexUppercase = mockupMetaOrder.indexOf("_PRINT HALFPAGE.jpg") + 1;
      const printHalfpageIndexCapped = mockupMetaOrder.indexOf("_Print Halfpage.jpg") + 1;
      const printHalfpageIndexMixed = mockupMetaOrder.indexOf("_Print halfpage.jpg") + 1;
      const printHalfpageIndexMixedSpaces = mockupMetaOrder.indexOf("_Print half page.jpg") + 1;
      const printHalfpageIndexSpaces = mockupMetaOrder.indexOf("_print half page.jpg") + 1;

      let displayCheckboxes = ``;
      console.log(mockupMetaOrder);
      mockupMetaOrder.forEach((el) => {
        // el = el.slice(0, -5);
        console.log(el); 
        if (el == "_LARGE MOBILE BANNER.jpg") {
          displayCheckboxes += `<input class="form__checkbox-input" type="checkbox" id="large-mobile-banner" name="largeMobileBanner" value="large-mobile-banner"><label class="form__checkbox-label" for="large-mobile-banner"><span class="form__checkbox-button"></span> 320x480</label><img class="viewico" data-img="${largeMobileBannerIndex}" src="/assets/img/eye.svg" /><br>`;
          displaySize.largeMobileBanner = false;
        } else if (el == "_LEADERBOARD.jpg") {
          displayCheckboxes += `<input class="form__checkbox-input" type="checkbox" id="leaderboard" name="leaderboard" value="leaderboard"><label class="form__checkbox-label" for="leaderboard"><span class="form__checkbox-button"></span> 728x90</label><img class="viewico" data-img="${leaderboardIndex}" src="/assets/img/eye.svg" /><br>`;
          displaySize.leaderboard = false;
        } else if (el == "_MMA.jpg") {
          displayCheckboxes += `<input class="form__checkbox-input" type="checkbox" id="mma" name="mma" value="mma"><label class="form__checkbox-label" for="mma"><span class="form__checkbox-button"></span> 320x50</label><img class="viewico" data-img="${mmaIndex}" src="/assets/img/eye.svg" /><br>`;
          displaySize.mma = false;
        } else if (el == "_MPU.jpg") {
          displayCheckboxes += `<input class="form__checkbox-input" type="checkbox" id="mpu" name="mpu" value="mpu"><label class="form__checkbox-label" for="mpu"><span class="form__checkbox-button"></span> 300x250</label><img class="viewico" data-img="${mpuIndex}" src="/assets/img/eye.svg" /><br>`;
          displaySize.mpu = false;
        } else if (el == "_SKIN.jpg") {
          displayCheckboxes += `<input class="form__checkbox-input" type="checkbox" id="skin" name="skin" value="skin"><label class="form__checkbox-label" for="skin"><span class="form__checkbox-button"></span> Skin</label><img class="viewico" data-img="${skinIndex}" src="/assets/img/eye.svg" /><br>`;
          displaySize.skin = false;
        } else if (el == "_SKYSCRAPER.jpg") {
          displayCheckboxes += `<input class="form__checkbox-input" type="checkbox" id="skyscraper" name="skyscraper" value="skyscraper"><label class="form__checkbox-label" for="skyscraper"><span class="form__checkbox-button"></span> 160x600</label><img class="viewico" data-img="${skyscraperIndex}" src="/assets/img/eye.svg" /><br>`;
          displaySize.skin = false;
        } else if (el == "_print halfpage.jpg") {
          displayCheckboxes += `<input class="form__checkbox-input" type="checkbox" id="print-halfpage" name="printHalfpage" value="print-halfpage"><label class="form__checkbox-label" for="print-halfpage"><span class="form__checkbox-button"></span> PRINT HALFPAGE</label><img class="viewico" data-img="${printHalfpageIndex}" src="/assets/img/eye.svg" />`;
          displaySize.skin = false;
        } else if (el == "_PRINT HALFPAGE.jpg") {
          displayCheckboxes += `<input class="form__checkbox-input" type="checkbox" id="print-halfpage" name="printHalfpage" value="print-halfpage"><label class="form__checkbox-label" for="print-halfpage"><span class="form__checkbox-button"></span> PRINT HALFPAGE</label><img class="viewico" data-img="${printHalfpageIndexUppercase}" src="/assets/img/eye.svg" />`;
          displaySize.skin = false;
        } else if (el == "_Print Halfpage.jpg") {
          displayCheckboxes += `<input class="form__checkbox-input" type="checkbox" id="print-halfpage" name="printHalfpage" value="print-halfpage"><label class="form__checkbox-label" for="print-halfpage"><span class="form__checkbox-button"></span> PRINT HALFPAGE</label><img class="viewico" data-img="${printHalfpageIndexCapped}" src="/assets/img/eye.svg" />`;
          displaySize.skin = false;
        } else if (el == "_Print halfpage.jpg") {
          displayCheckboxes += `<input class="form__checkbox-input" type="checkbox" id="print-halfpage" name="printHalfpage" value="print-halfpage"><label class="form__checkbox-label" for="print-halfpage"><span class="form__checkbox-button"></span> PRINT HALFPAGE</label><img class="viewico" data-img="${printHalfpageIndexMixed}" src="/assets/img/eye.svg" />`;
          displaySize.skin = false;
        } else if (el == "_Print half page.jpg") {
          displayCheckboxes += `<input class="form__checkbox-input" type="checkbox" id="print-halfpage" name="printHalfpage" value="print-halfpage"><label class="form__checkbox-label" for="print-halfpage"><span class="form__checkbox-button"></span> PRINT HALFPAGE</label><img class="viewico" data-img="${printHalfpageIndexMixedSpaces}" src="/assets/img/eye.svg" />`;
          displaySize.skin = false;
        } else if (el == "_print half page.jpg") {
          displayCheckboxes += `<input class="form__checkbox-input" type="checkbox" id="print-halfpage" name="printHalfpage" value="print-halfpage"><label class="form__checkbox-label" for="print-halfpage"><span class="form__checkbox-button"></span> PRINT HALFPAGE</label><img class="viewico" data-img="${printHalfpageIndexSpaces}" src="/assets/img/eye.svg" />`;
          displaySize.skin = false;
        }
      });

      showForm = `<form class="modal-add-to-cart" action="/account/add-to-basket" method="post">
			<input type="hidden" name="resRegion" value="${region}">
			<input type="hidden" name="resProduct" value="${productIndex._id}">
      <div class="formats">
        <input class="form__checkbox-input" type="checkbox" id="dmpu" name="dmpu" value="dmpu">
        <label class="form__checkbox-label" for="dmpu"><span class="form__checkbox-button"></span> 300x600</label><img class="viewico" data-img="0" src="/assets/img/eye.svg" /><br>
        ${displayCheckboxes}
      </div>
			<button id="submitReq" class="btn" type="submit" disabled value="add">Reserve</button>
      </form>`;

      if (window.location.pathname === "/products/search/") {
        sharebBackButton = "#";
      } else {
        sharebBackButton = `/products/${productIndex.category}`;
      }
    } else {
      showForm = "";
      sharebBackButton = `/products/share/${categoryProduct[productLink].slug}/${productIndex.category}`;
    }

    // Check if product uses Google Cloud Storage or S3

    let cloudStorage = null;
    const prodArr = productIndex.image.split("/");
    console.log(prodArr)
    const imageFileName = prodArr[2];
    console.log(imageFileName)

    if (imageFileName == "storage.googleapis.com") {
      cloudStorage = "GCS";
    } else if (imageFileName == "creative-store.s3.eu-west-2.amazonaws.com") {
      cloudStorage = "S3"
    }
    console.log(cloudStorage)


    filterImages(productIndex, skipMain).then((skip = false) => {
      htmlsource = `
		<div class="modal">
			<div class="modal-content">
				<div class="inner">
					<div class='thumbs'>
						<div class="wrap">
							<div class="product-thubnail">
									<div class="thumbnail-inner" id="prodthumbs">
                  ${
                    skip
                      ? ``
                      : `
                    <div class="thumb-picture">
                          <img src="${productIndex.image.replace("-full", "-thumb")}" alt="">
                    </div>
                      `
                  }
									</div>
							</div>
						</div>
					</div>
					<div class="bigimage">
            <div class="product-gallery" id="prodgal">
              ${
                skip
                  ? `

                `
                  : `
                <div data-title="${productIndex.urn}_dmpu.jpg"><img src="${productIndex.image}" alt="Image"></div>
                `
              }
						</div>
						<div class="imgtitle" id='imgtitle'></div>
					</div>
					<div class="content">
						<div class="content__header">
							<a href="${sharebBackButton}"><span class="close-button">Close</a>
						</div>
						<div class="content__body">
							<div class="details">
								<p>TRN: <span class="meta">${productIndex.urn}</span></p>
								${showForm}
							</div>
							<div class="copytext">
								<div class="confirmation"><span class="icon-checkmark"></span> Copied to clipboard</div>
								<div class="share-box">https://${link}${productIndex.category}/${productIndex.urn}</div>
							</div>
						</div>
						<div class="content__footer">
							<div class="btn btn--trans_yel share-button">Share Template <span class='shareico'></span></div>
						</div>

					</div>
				</div>
			</div>
		</div>`;

      htmlBody.insertAdjacentHTML("beforeend", htmlsource);
      const thumbsSec = document.getElementById("prodthumbs");
      const galSec = document.getElementById("prodgal");

      if (document.querySelector(".contentprodssingle")) {
        const modal = document.querySelector(".modal");
        if (!modal) return;
        modal.addEventListener("click", (e) => {
          if (e.target === modal) {
            modal.classList.remove("show-modal");
            setTimeout(() => {
              modal.remove();
            }, 300);
            const uri = window.location.toString();
            location.href = uri.substring(0, uri.lastIndexOf("/") + 1);
          }
        });
      }

      productIndex.mockupMeta.map((thumb, index) => {
        thumbsSec.innerHTML += `
				<div class="thumb-picture"><img src="${thumb.thumb.replace("-full", "-thumb")}" alt=""></div>
      `;

        galSec.innerHTML += `
        <div data-title="${thumb.name}"><img src="${thumb.thumb.replace("-thumb", "-full")}" alt="Image"/></div>
      `;
      });
      const imageTitle = document.getElementById("imgtitle");

      setTimeout(function() {
        // Product modal
        if (document.querySelector(".branded")) {
          $(".product-gallery")
            .not(".slick-initialized")
            .slick({
              slidesToShow: 1,
              slidesToScroll: 1,
              mobileFirst: true,
              swipeToSlide: true,
              accessibility: true,
              centerPadding: "0",
              focusOnSelect: true,
              centerMode: true,
              rows: 0,
            });

          $(".product-gallery").slickLightbox({
            itemSelector: "img",
            navigateByKeyboard: true,
            src: "src",
          });
        } else {
          $(".product-gallery")
            .not(".slick-initialized")
            .slick({
              slidesToShow: 1,
              slidesToScroll: 1,
              mobileFirst: true,
              swipeToSlide: true,
              accessibility: true,
              centerPadding: "0",
              focusOnSelect: true,
              centerMode: true,
              rows: 0,
            });
        }
        // Thumbnail
        $(".thumbnail-inner")
          .not(".slick-initialized")
          .slick({
            asNavFor: ".product-gallery",
            arrows: false,
            dots: false,
            slidesToShow: 15,
            centerMode: false,
            centerPadding: "10px",
            focusOnSelect: true,
            vertical: true,
          });

        const title = formatTitle($(".product-gallery .slick-active.slick-current").attr("data-title"));
        imageTitle.innerText = title;
      }, 200);

      $(".product-gallery").on("afterChange", function(event, slick, currentSlide, nextSlide) {
        const title = formatTitle($(".product-gallery .slick-active.slick-current").attr("data-title"));
        imageTitle.innerText = title;
      });

      const eyeNavs = document.querySelectorAll(".viewico");
      const slider = $(".product-gallery");

      for (let i = 0; i < eyeNavs.length; i++) {
        const nav = eyeNavs[i];
        nav.addEventListener("click", (e) => {
          console.log(e);
          console.log(e.target.dataset.img);
          slider[0].slick.slickGoTo(parseInt(e.target.dataset.img));
        });
      }
      const checkboxes = document.querySelectorAll(".form__checkbox-input");
      const submitBtn = document.getElementById("submitReq");

      const checkInputs = () => {
        if ($(".modal-add-to-cart input:checkbox:checked").length > 0) {
          submitBtn.disabled = false;
        } else {
          submitBtn.disabled = true;
        }
      };

      for (let index = 0; index < checkboxes.length; index++) {
        checkboxes[index].addEventListener("change", () => {
          checkInputs();
        });
      }
    });
  }

  // Share
  function shareLink() {
    $(".share-button").on("click", function() {
      document.querySelector(".share-box").classList.toggle("show");
      const btntxt = $(this).text();
      const copy = document.querySelector(".share-box").innerHTML;
      if (
        (navigator.vendor != null && navigator.vendor.match(/Apple Computer, Inc./) && navigator.userAgent.match(/iPhone/i)) ||
        navigator.userAgent.match(/iPod/i)
      ) {
        if (document.querySelector(".share-box").classList.contains("show")) {
          $(".confirmation")
            .hide()
            .html("Select and send the following link to your clients.")
            .fadeIn(100);
        } else {
          $(".confirmation")
            .hide()
            .html("Select and send the following link to your clients.")
            .fadeOut(200);
        }
      } else {
        const $temp = $("<input>");
        $("body").append($temp);
        $temp.val(copy).select();
        document.execCommand("copy");
        $temp.remove();
        if (document.querySelector(".share-box").classList.contains("show")) {
          $(".confirmation")
            .hide()
            .html("A <b>Shareable link</b> for your clients has been copied to your clipboard.")
            .fadeIn(100);
        } else {
          $(".confirmation")
            .hide()
            .html("A <b>Shareable link</b> for your clients has been copied to your clipboard.")
            .fadeOut(200);
        }
      }
    });

    $(".share-box").on("click", function() {
      const range = document.createRange();
      const selection = window.getSelection();
      range.selectNodeContents(this);
      selection.removeAllRanges();
      selection.addRange(range);
    });
  }

  $(".btn-delete").on("click", function(event) {
    if (confirm("Are you sure you want to delete") === true) {
    } else {
      event.preventDefault();
    }
  });

  // Show reset form
  $(".resetBtn").on("click", function() {
    document.querySelector(".pwd-reset").classList.toggle("show");
  });

  // Edit
  $(".uploadreplace").on("click", function() {
    $(".replacefile").fadeOut();
    this.setAttribute("required", "true");
  });

  // Cart Timer
  if (typeof cartItem !== "undefined") {
    // load only on pages with the timer
    if (document.getElementById("cartTimer")) {
      // Set the date we're counting down to
      const countDownDate = new Date(cartTimer).getTime();
      // Update the count down every 1 second
      const countdownfunction = setInterval(function() {
        // Get todays date and time
        const now = new Date().getTime();

        // Find the distance between now an the count down date
        const distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Output the result in an element with id="demo"
        document.getElementById("cartTimer").innerHTML = `${hours} Hours ${minutes} Minutes ${seconds} Seconds`;

        // If the count down is over, write some text
        if (distance < 0) {
          clearInterval(countdownfunction);
          document.querySelector(".flash--warning a").remove();
          document.querySelector(".flash--warning p ").innerHTML = "The item in your cart has expired and will be removed.";
        }
      }, 1000);
    }
  }
});

let clickCheck = 0;

$("#createprodbtn").on("click", () => {
  if (clickCheck === 0) {
    clickCheck = 1;
    $("#finalsubmit").submit();
    $("#createprodbtn").attr("disabled", "disable");
    $("#createprodbtn").val("Uploading...");
    $("#createprodbtn").addClass("disabled");
  }
});

if (document.querySelector(".creative-index") || document.querySelector(".events-index") || document.querySelector(".promotions-index")) {
  $(".products__item").attr("data-rellax-speed", "-1");
  $(".products__item:nth-child(5n-1), .products__item:nth-child(5n-3)").addClass("dropped");
  $(".dropped").attr("data-rellax-speed", "1");
}

if (document.getElementById("analyticsFilter")) {
  document.querySelector("#dateTo").valueAsDate = new Date();
  const grid = document.querySelector("#regsgrid");
  const selector = document.getElementById("toggle");
  selector.addEventListener("change", () => {
    console.log("toggled");
    regsVisibility();
  });

  function regsVisibility() {
    if (selector.value === "false") {
      grid.style.display = "flex";
    } else {
      grid.style.display = "none";
      document.querySelectorAll("#regsgrid input").forEach((elem) => {
        elem.checked = false;
      });
    }
  }
  regsVisibility();
}

if (document.getElementById("editregionspage")) {
  let editBtns = document.querySelectorAll(".editdomain");

  editBtns.forEach((button) => {
    button.addEventListener("click", (e) => {
      console.log(e.target.parentNode);
      if (e.target.innerText === "Hide") {
        document.querySelector(`.editform-${e.target.dataset.number}`).style.display = "none";
        e.target.innerText = "Edit";
        e.target.parentNode.classList.remove("active");
        return;
      }
      const myRef = e.target.dataset.number;
      e.target.innerText = "Hide";
      e.target.parentNode.classList.add("active");
      document.querySelector(`.editform-${myRef}`).style.display = "block";
    });
  });
}

if (document.getElementById("createproduct")) {
  // TRN Filters
  let numLeading;

  // Search tags
  $(".select").on("change", function(e) {
    const selectVal = e.target[e.target.selectedIndex].value;
    const searchTage = document.querySelector(".searchtags");
    const productName = document.querySelector(".productname");

    axios
      .get(`/api/last/${selectVal}`)
      .then(function(response) {
        const part = response.data.split(/([A-Za-z]+)/g);

        // Store both character and numbers
        const characters = part[1]; // Characters
        const digits = part[2]; // numbers

        // Stores the previous variable as a number and add 1
        let result = +digits + 1;

        /*
          1: Check if product starting ref is 2 or 3 characters long
          2: Append the correct amount of zeros
        */
        const newTRN =
          characters.length === 2
            ? `${characters}0000${result}` //ex00003
            : `${characters}000${result}`; //exa0003

        productName.value = newTRN;
      })
      .catch(function(err) {
        console.log("No Products exist in this category");
      });

    // switch (selectVal) {
    //   case "education":
    //     searchTage.innerText =
    //       "education school primary secondary high independent college university learning homework textbooks uniform tutor teacher mentor pupils students lessons grade subjects maths english science music history geography languages art sport french german spanish performing arts pre-school nursery playtime holidays break time extra-curricular competition lunchtime equipment biology chemistry physics re bus coach transport committee union pta after-school club dance breaks games dt it private public comprehensive curriculum";
    //     numLeading = 5;
    //     break;

    //   case "business":
    //     searchTage.innerText =
    //       "business law legal finance accounting broker property insurance assets marketing growth Solicitor asset management employees employer report banking retail charity human resources pr public relations people market research trade revenue broker";
    //     numLeading = 5;
    //     break;

    //   case "leisure":
    //     searchTage.innerText =
    //       "leisure wedding bar drinks pub venue restaurant weekend cinema theatre food indian chinese burger italian pizza kebab party bowling cafe coffee movie shopping mall entertainment things to do sport music what’s on quiz dancing club karaoke casino tv outdoor indoor relax what’s on";
    //     numLeading = 5;
    //     break;

    //   case "motors":
    //     searchTage.innerText =
    //       "motor car service new used lease finance motor engine lease petrol diesel new registration plate four-door five-door hatchback saloon estate convertible car van motorbike caravan motorhome alloy wheels tyres seat boot storage sat nav bluetooth heating parking sensors safety carseat bike rack roof rack servicing dealer trade showroom ";
    //     numLeading = 5;
    //     break;

    //   case "property":
    //     searchTage.innerText =
    //       "property house home sale rent help to buy listings promo detached terrace semi-detached flat apartment bedrooms reception rooms estate agent letting serviced price first-time buyer stamp duty mortgage deposit survey conveyancing moving move viewing auction";
    //     numLeading = 5;
    //     break;

    //   case "recruitment":
    //     searchTage.innerText =
    //       "hire hiring recruit recruitment job advertisement applicant role salary company experience results interview work position vacancy opening full-time part-time contract agency temp cv resume recruiting seasonal hourly rate";
    //     numLeading = 5;
    //     break;

    //   case "carpets-flooring":
    //     searchTage.innerText =
    //       "carpets flooring laminate tiles tiling underlay heating wood steam clean real wood stone marble ceramic installation quote maintenance pile wool rugs mats warm heating drafts bathroom kitchen stairway fitting ";
    //     numLeading = 4;
    //     break;

    //   case "electrical":
    //     searchTage.innerText =
    //       "electrical fridge freezer television tv oven microwave dvd player cd player tumble dryer washing machine computer mac hoover printer telephone mobile smart phone playstation console xbox hairdryer apple tv smart tv coffee machine radio charger usb mp3 speakers soundbar record player vinyl bluetooth camera slr photography editing software hardware sky pc laptop tablet ipad kindle headphones smart watch hair straighteners dishwasher toaster blender";
    //     numLeading = 4;
    //     break;

    //   case "furniture-home":
    //     searchTage.innerText =
    //       "home furniture house furnishing lamp home sofa chair table dressing table bed cupboard wardrobe drawers dresser study desk cabinet stool sofa-bed sideboard bedside table shelving storage furnishings rug lighting settee cushion fire fireplace heating mirror lampshade frame picture wallpaper paint decorate decorating improvement doors";
    //     numLeading = 4;
    //     break;

    //   case "glazing":
    //     searchTage.innerText =
    //       "windows conservatory extension glazing double triple upvc shiny self-clean tinted mosaic stained glass velux curtains skylight natural light garden room orangery conservatory blinds greenhouse porch patio doors sliding doors heating bi-fold sun room garden lounge";
    //     numLeading = 4;
    //     break;

    //   case "kitchens-bedrooms-bathrooms":
    //     searchTage.innerText =
    //       "retail kitchen sink bath bed bedroom bathroom cabinet wardrobe hob cabinet cooking worktop drawers cupboards island mixer tap gas induction drainer storage sleeping bedding children’s bed king size queen size double bed single bed cabin bunk bed lighting pillow duvet fitted design mirror shower wet room toilet wc";
    //     numLeading = 4;
    //     break;

    //   case "services":
    //     searchTage.innerText =
    //       "services plumber electrician painter decorate diy house-move removal company cleaner handyman man-and-van estate agent ironing-service inspection survey landscaper gardener tree-surgeon pest control damp proofing driveway block paving jetwash ladder chimney-sweep roofer builder plasterer architect engineer interior designer upholstery seamstress dry cleaners carpet-cleaning drain window-cleaner carpenter dentist doctor optician library ";
    //     numLeading = 5;
    //     break;

    //   case "health-beauty":
    //     searchTage.innerText =
    //       "health beauty hair makeup hairdressers barbers shave diet detox fitness gym glasses eye test vision strength lift weight relax spa makeover treatment styling razor cut teeth dental dentist white whitening unwind skin";
    //     numLeading = 5;
    //     break;

    //   case "agriculture":
    //     searchTage.innerText =
    //       "agriculture farming fields tractor plow pig cow crops water vegetables tomatoes wheat potatoes greenhouse light bird fertiliser pesticides jcb digger spade digging soil grass plants organic home-made home-grown fruit seeds grow-your-own pick-your-own horses ponies calves chickens show dogs cats sheep eggs cheese milk farm-shop jam marmalade chutney flour straw hay pets corn grain";
    //     numLeading = 5;
    //     break;

    //   case "january-sales":
    //     searchTage.innerText =
    //       "seasonal january sales sale bargain discount shopping cheap money online retail bogof high-street designer brands retail park early";
    //     numLeading = 4;
    //     break;

    //   case "easter-spring":
    //     searchTage.innerText =
    //       "seasonal egg easter bunny chocolate jesus god religion church fair parade bank holiday presents spring rabbit lamb flowers basket";
    //     numLeading = 4;
    //     break;

    //   case "events":
    //     searchTage.innerText =
    //       "awards ceremony of the year business sports environment nomination entry success gala event";
    //     numLeading = 5;
    //     break;

    //   case "weddings":
    //     searchTage.innerText =
    //       "weddings, marriage, engagement, proposal, wedding band, reception, venue, flowers, hair, make-up, styling, table plans, guests, drinks, cocktails, champagne, groom, bridesmaid, mother of the bride, page boy, confetti, church, ceremony, groomsmen, best man, maid of honor, beauty, photography, dancefloor, invitations, ring, vicar, church, catering, vows, tie the knot, get hitched, cold feet, usher, wedding dress, tuxedo, honeymoon, holiday, vintage car, bride, wedding breakfast";
    //     numLeading = 5;
    //     break;
    // }
  });
}

function youtube_parser(url) {
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
}

const previewVideoHtml = (src) => {
  return `
    <div>
      <img style="width: 150px; height: 150px; object-fit: cover;" src="${src}" />
    </div>
  `;
};

if (document.getElementById("addnewvideo")) {
  let mode = "youtube";
  const YouTube = document.getElementById("yt-embed");
  const ytPreview = document.getElementById("yt-preview");
  const ytLabel = document.getElementById("yt-label");
  const selector = document.getElementById("video-selector");

  const code = document.getElementById("code");
  const thumb = document.getElementById("thumb");

  function setVideo(e) {
    if (mode === "vimeo") {
      const embed = e.target.value;
      const match = embed.match(/player\.vimeo\.com\/video\/([0-9]*)/);
      axios.get(`https://vimeo.com/api/v2/video/${match[1]}.json`).then((res) => {
        const videoInfo = res.data[0];
        ytPreview.innerHTML = previewVideoHtml(videoInfo.thumbnail_large);
        code.value = match[1];
        thumb.value = videoInfo.thumbnail_large;
      });
    } else {
      const id = youtube_parser(e.target.value);
      const thumbnail = `https://img.youtube.com/vi/` + id + `/0.jpg`;

      ytPreview.innerHTML = previewVideoHtml(`https://img.youtube.com/vi/${id}/0.jpg`);

      code.value = id;
      thumb.value = thumbnail;
    }
  }

  selector.addEventListener("change", (e) => {
    ytPreview.innerHTML = "";
    code.value = "";
    thumb.value = "";
    YouTube.innerText = "";

    mode = e.target.value;
    if (mode === "vimeo") {
      ytLabel.innerText = "vimeo Embed Code";
    } else {
      ytLabel.innerText = "youtube url";
    }
  });

  YouTube.addEventListener("change", (e) => {
    setVideo(e);
  });

  mode = selector.value;
  setVideo({
    target: {
      value: YouTube.value,
    },
  });
}

function formatTitle(title) {
  if (title) {
    const inner = title.substring(title.lastIndexOf("_") + 1, title.lastIndexOf(".")).toLowerCase();
    switch (inner) {
      case "dmpu":
        return "300x600";
      case "mpu":
        return "300x250";
      case "mma":
        return "320x50";
      case "leaderboard":
        return "728x90";
      case "skyscraper":
        return "160x600";
      case "large mobile banner":
        return "320x480";
      default:
        return inner;
        break;
    }
  } else {
    return "";
  }
}

function filterImages(products, skipMain) {
  return new Promise((resolve, reject) => {
    for (let i = 0; i < products.filters.length; i++) {
      const filter = products.filters[i];
      const index = i;

      if (filter === "dmpu" || filter === "300x600") {
        skipMain = true;
      }

      for (let o = 0; o < products.mockupMeta.length; o++) {
        const meta = products.mockupMeta[o];

        if (formatTitle(meta.name) === filter) {
          products.mockupMeta.splice(o, 1);
          products.mockups.splice(o, 1);
        }
      }

      if (index === products.filters.length - 1) {
        setTimeout(() => {
          resolve(skipMain);
        }, 100);
      }
    }
  });
}

// function checkBasketLength() {
// 	const removeButtons = document.querySelectorAll('form.cart_form button');
// 	const withProoferBtn = document.getElementById('withProofer');
// 	const withoutProoferBtn = document.getElementById('withoutProofer');

// 	console.log(removeButtons.length);
// 	const checkCartLength = () => {
// 	  if(basketContents.cart.length > 1){
// 		  console.log("disable buttons")
// 		  // submitBtn.disabled = false;
// 		} else {
// 		  console.log("enable buttons")
// 		  // submitBtn.disabled = true;
// 		}
// 	}
// }
