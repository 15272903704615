const pageBody = document.getElementsByTagName('body')[0];

async function load() {
	console.log('load');
	if (document.getElementById('loader')){
		if (!document.getElementById('loader').classList.contains('loading')) {
			document.getElementById('loader').classList.add('loading');
			return true;
		}
	} else {
		init()
		setTimeout(() => {
			load()
		}, 600);
	}
}

function ready() {
	console.log('ready');
	if (pageBody.classList.contains('loading')){
		pageBody.classList.remove('loading');
	}
	if (document.getElementById('loader').classList.contains('loading')) {
		document.getElementById('loader').classList.remove('loading');
		return true;
	}
}

function init() {
	console.log('loader initialised');

	// pageBody.innerHTML += `
	// 	<div class='loader' id='loader'>
	// 		<iframe style='width: 400px; overflow: hidden; border: none; outline: none; height: 400px; background-color: rgba(0,0,0,0); top: 50%; left: 50%; position: absolute; transform: translate(-50%, -50%);' src="/assets/load/insights_loader2.html"></iframe>
	// 	</div>
	// `
	pageBody.innerHTML += `
		<div class='loader' id='loader'></div>
	`
	pageBody.classList += ' loading'
	setTimeout(() => {
		ready();
	}, 2000);
}

const loader = {
	'init': init,
	'load': load,
	'ready': ready
}

export default loader;