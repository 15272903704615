function init() {
  // Navigation
  const menuList = document.querySelectorAll('.menu-list');

  menuList.forEach(function(item) {
    item.addEventListener('click', function() {
      const self = this;

      if (!document.querySelector('.dropdown').classList.contains('active')) {
        self.querySelector('.dropdown').classList.add('active');
      } else {
        document.querySelector('.dropdown').classList.remove('active');
      }
    });
  });

  const searchBtn = document.getElementById('searchBtn');
  const searchForm = document.getElementById('searchForm');

  searchBtn.addEventListener('click', e => {
    searchForm.classList.toggle('search-display');
    setTimeout(() => {
      searchForm.classList.toggle('search-transition');
    }, 50);
  });

  const mainMenu = document.getElementById('mainmenu');
  const menuIcon = document.getElementById('menuIco');

  menuIcon.addEventListener('click', () => {
    $('#mainmenu').toggleClass('open');
  });

  const creativeSubLink = document.getElementById('menu-has-children-1');
  const creativeSubMenu = document.getElementById('menu-child-1');

  if (creativeSubLink) {
    creativeSubLink.addEventListener('click', e => {
      e.preventDefault();
      if (mainMenu.classList.contains('open')) {
        creativeSubMenu.classList.toggle('menu-child-visible');
      }
    });
  }

  // Search box
  const searchIcon = document.querySelector('.header__search--icon');
  const searchBox = document.querySelector('.header__search--box');

  if (searchIcon) {
    searchIcon.addEventListener('click', function() {
      searchBox.classList.toggle('show');

      // Check if menu is fixed
      if (document.querySelector('body').classList.contains('fixed')) {
        document.querySelector('.header-wrap').classList.toggle('show');
      }
    });
  }

  // Scroll to top (Footer Scroll)
  $('.scrolltop').on('click', function(event) {
    event.preventDefault();
    const target = $('#stores');
    $('body,html').animate(
      {
        scrollTop: 0,
      },
      500
    );
  });

  // Navigation scrolling
  $(
    '.navigation .navigation__link[href*="#"]:not([href="#"]), .feature-box a[href*="#"]:not([href="#"])'
  ).click(function(event) {
    if (!document.getElementById('landingpage')) {
      event.preventDefault();
    }

    if (siteDim.width() < 1140) {
      document.querySelector('.navigation').classList.toggle('active');

      // Simulate menu click if not feature box
      if (!event.target.classList.contains('btn')) {
        document.querySelector('.menu-check').click();
      }
    }

    if (
      location.pathname.replace(/^\//, '') ===
        this.pathname.replace(/^\//, '') &&
      location.hostname === this.hostname
    ) {
      let target = $(this.hash);
      target = target.length ? target : $(`[name=${this.hash.slice(1)}]`);

      setTimeout(() => {
        if ($('.navigation').hasClass('active')) {
          // $('.menu-check').attr('checked', false);
          $('.navigation').removeClass('active');
        }
      }, 100);

      // Check if fixed header is active
      if (document.querySelector('body').classList.contains('fixed')) {
        if (target.length) {
          $('html, body').animate(
            {
              scrollTop: target.offset().top - 20,
            },
            1000
          );

          return false;
        }
      } else if (target.length) {
        $('html, body').animate(
          {
            scrollTop: target.offset().top - 65,
          },
          1000
        );
        return false;
      }
    }
  });
}

const navbar = {
  init,
};

export default navbar;
