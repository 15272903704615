import axios from 'axios';
let apiResponse;

let loader = `
<svg style="margin-top: 100px" width="80" height="80" viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg" stroke="#fff">
  <g fill="none" fill-rule="evenodd" transform="translate(1 1)" stroke-width="2">
    <circle cx="22" cy="22" r="6" stroke-opacity="0">
      <animate attributeName="r"
        begin="1s" dur="2s"
        values="6;22"
        calcMode="linear"
        repeatCount="indefinite" />
      <animate attributeName="stroke-opacity"
        begin="1s" dur="2s"
        values="1;0" calcMode="linear"
        repeatCount="indefinite" />
      <animate attributeName="stroke-width"
        begin="1s" dur="2s"
        values="2;0" calcMode="linear"
        repeatCount="indefinite" />
    </circle>
    <circle cx="22" cy="22" r="6" stroke-opacity="0">
      <animate attributeName="r"
        begin="2s" dur="2s"
        values="6;22"
        calcMode="linear"
        repeatCount="indefinite" />
      <animate attributeName="stroke-opacity"
        begin="2s" dur="2s"
        values="1;0" calcMode="linear"
        repeatCount="indefinite" />
      <animate attributeName="stroke-width"
        begin="2s" dur="2s"
        values="2;0" calcMode="linear"
        repeatCount="indefinite" />
    </circle>
    <circle cx="22" cy="22" r="8">
      <animate attributeName="r"
        begin="0s" dur="1s"
        values="6;1;2;3;4;5;6"
        calcMode="linear"
        repeatCount="indefinite" />
    </circle>
  </g>
</svg>
`;

const apiLookup = () => {
  return axios.get('/api/proof/' + proofId)
}

function checkout() {
  const DomElem = document.getElementById("iframe");

  DomElem.innerHTML = loader;

  if (!proofId) return;
  const checkApi = setInterval(async () => {
    const DomElem = document.getElementById("iframe");
    const { data } = await apiLookup();
    console.log(data);

    if(Object.keys(data)[0] === 'success'){
      clearInterval(checkApi);
      DomElem.innerHTML = `
      <iframe src=${data.success} style="margin-top: 50px; width: 100%; height: 100%; top: 0; left: 0; right: 0; bottom: 0; position: absolute; border: none;" class="proof-iframe"></iframe>
      `;

    }
  }, 1000);
}

export default checkout;