import axios from "axios";
import tilt from "./tilt-jquery";

const openVideoModal = e => {
  console.log(e);
  console.log(e.target.dataset);
  createModal(e.target.dataset.contents);
}

function init() {
  // Get site width on load
  let siteDim;
  document.addEventListener("DOMContentLoaded", function() {
    siteDim = $(window);
  });

  // Ajax call/ Api only works with logged in users
  if (typeof userLogin !== "undefined") {
    axios
      .get("/api/creatives")
      .then(({ data }) => {
        let html;
        for (let i = 0; i < data.length; i++) {
          html = `<div class="carousel__item">
            <a href="${data[i].image}" class="carousel__link" data-caption="<h5>${data[i].name}</h5><p>${data[i].description}</p>">
              <img src="${data[i].image}" alt="${data[i].name}"/>
              <h5>${data[i].name}</h5>
            </a>
          </div>`;

          if (document.querySelector("#inspiration")) {
            document
              .querySelector("#inspiration")
              .insertAdjacentHTML("beforeend", html);
          }
        }
      })
      .then(() => {
        // Creative Inspiration
        $("#inspiration").slick({
          prevArrow:
            '<img class="slick-prev" src="/assets/reach/img/arr-c-l.svg" />',
          nextArrow:
            '<img class="slick-next" src="/assets/reach/img/arr-c-r.svg" />',
          mobileFirst: true,
          slidesToShow: 2,
          swipeToSlide: true,
          rows: 0,
          responsive: [
            {
              breakpoint: 550,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 1140,
              settings: {
                slidesToShow: 4,
              },
            },
          ],
        });
      })
      .then(() => {
        $(".recently-added .carousel__item").tilt({
          maxTilt: -40,
          perspective: 1400,
          easing: "cubic-bezier(.03,.98,.52,.99)",
          speed: 1200,
          glare: true,
          maxGlare: 0.6,
        });

        $("#inspiration").slickLightbox({
          itemSelector: "a",
          navigateByKeyboard: true,
          caption: 'caption'
        });
      })
      .catch(function(error) {
        console.log(error);
      });

  $("#videocarousel").slick({
      prevArrow:
        '<img class="slick-prev" src="/assets/reach/img/arr-c-l.svg" />',
      nextArrow:
        '<img class="slick-next" src="/assets/reach/img/arr-c-r.svg" />',
      mobileFirst: true,
      slidesToShow: 2,
      swipeToSlide: true,
      rows: 0,
      responsive: [
        {
          breakpoint: 550,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 1140,
          settings: {
            slidesToShow: 4,
          },
        },
      ],
    });

    const videos = document.querySelectorAll('.videomodal');

    videos.forEach(video => {
      video.addEventListener('click' , openVideoModal)
    })

  }

  const cardsWithSubs = document.querySelectorAll(".card-has-subs");
  let openCat;

  cardsWithSubs.forEach((cardWithSubs) => {
    cardWithSubs.addEventListener("click", (e) => {
      e.preventDefault();
      if (openCat === e.target.dataset.cat) {
        e.target.classList.remove("catActive");
        e.target.parentNode.classList.remove("cat-open");
        closeSubcats(openCat);
        openCat = null;
      } else {
        e.target.classList.add("catActive");
        e.target.parentNode.classList.add("cat-open");

        closeSubcats(openCat);
        openCat = e.target.dataset.cat;
        const subCats = document.querySelectorAll(`.card-subs-${openCat}`);
        subCats.forEach((subCat, idx) => {
          subCat.classList.add("active", `active__${idx}`);
        });
      }
    });
  });
}

function closeSubcats(name) {
  const subCats = document.querySelectorAll(`.card-subs-${name}`);
  subCats.forEach((subCat) => {
    subCat.classList.remove("active");
  });
}

const landingPage = {
  init,
  // categoriesFlip,
};

export default landingPage;

function createModal(data) {
  console.log(data);
  const content = JSON.parse(data);
  const htmlBody = document.querySelector("body");

  const htmlsource = `
		<div class="modal marketingsub">
			<div class="modal-content" style="padding: 45px 0 25px;">
				<div class="inner" style="width: 660px">
					<div class="close-button">Close</div>
          <h1>${content.title}<span class="white">.</span></h1>
          ${content.source === 'vimeo' ? `
            <iframe style="max-width: 100%;" src="https://player.vimeo.com/video/${content.code}" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
          ` : `
            <iframe style="max-width: 100%;" width="560" height="315" src="https://www.youtube.com/embed/${content.code}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          `}
          <p style="margin-top: 20px; color: #fff;">${content.description}</p>
        </div>
			</div>
		</div>`;

  htmlBody.insertAdjacentHTML("beforeend", htmlsource);

  const modal = document.querySelector(".modal");
  const closeButton = document.querySelector(".close-button");

  // const linksec = document.getElementById("sublinks");
  // linksec.innerHTML = "";

  // modalcontents.links.map((link) => {
  //   linksec.innerHTML += `
	// 		<a href="${link.url}"> - ${link.title}</a>
	// 	`;
  // });

  function toggleModal() {
    modal.classList.toggle("show-modal");
  }

  function modalDestroy() {
    toggleModal();
    setTimeout(function() {
      modal.remove();
    }, 300);
  }

  function windowOnClick(event) {
    if (event.target === modal) {
      modalDestroy();
    }
  }
  closeButton.addEventListener("click", modalDestroy);
  window.addEventListener("click", windowOnClick);

  toggleModal();
}
